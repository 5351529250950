{
  "version": "0.0.8",
  "name": "@bitget-wallet/web3-sdk",
  "license": "MIT",
  "main": "dist/index.cjs.js",
  "module": "dist/index.es.js",
  "jsdelivr": "dist/index.es.js",
  "typings": "dist/types/src/index.d.ts",
  "files": [
    "/dist",
    "/types",
    "/example/index.html"
  ],
  "keywords": [
    "solana",
    "tronLink",
    "tronweb",
    "evm",
    "cipherbrowser",
    "bitget",
    "bitgetWallet",
    "bitkeep",
    "bitkeepWallet",
    "eth",
    "ether",
    "ethereum",
    "etherium",
    "injection",
    "wallet",
    "web3"
  ],
  "description": "",
  "scripts": {
    "clean": "rimraf dist",
    "types": "tsc",
    "predev": "npm run clean && npm run types",
    "prebuild": "npm run clean && npm run types",
    "dev": "ts-node  ./scripts/esbuild.dev.js",
    "build": "node ./scripts/esbuild.pro.js",
    "test": "",
    "lint": "",
    "prepare": ""
  },
  "prettier": {
    "printWidth": 80,
    "semi": true,
    "singleQuote": true,
    "trailingComma": "es5"
  },
  "devDependencies": {
    "@types/node": "^20.4.5",
    "@types/node-fetch": "^2.6.4",
    "esbuild": "^0.18.10",
    "rimraf": "^5.0.1",
    "ts-node": "^10.9.1",
    "typescript": "^5.1.6"
  },
  "publishConfig": {
    "access": "public",
    "registry": "https://registry.npmjs.org/"
  },
  "dependencies": {
    "@metamask/safe-event-emitter": "^3.0.0",
    "@solana/web3.js": "^1.78.0",
    "eventemitter3": "^5.0.1"
  }
}
