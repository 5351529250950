
import { version } from '../../package.json';
import logoPNG28 from '../assets/logo/png/bitget_wallet_logo_28.png';
import logoPNG256 from '../assets/logo/png/bitget_wallet_logo_256.png'
import logoSVG28 from '../assets/logo/svg/bitget_wallet_logo_28.svg';
import logoSVG256 from '../assets/logo/svg/bitget_wallet_logo_256.svg'
import { walletIconBase64 } from '../assets/wallet-logo'

/**
* @description get download Links
* @return void
*/
export const getDownloadLinks = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return {
    website: `https://web3.bitget.com/${lang}/wallet-download`,
    android: `https://web3.bitget.com/${lang}/wallet-download?type=0`,
    ios: `https://apps.apple.com/app/bitkeep/id1395301115`,
    chrome: `https://web3.bitget.com/${lang}/wallet-download?type=2`,
    googlePlay: 'https://play.google.com/store/apps/details?id=com.bitkeep.wallet',
    appleStore: 'https://apps.apple.com/app/bitkeep/id1395301115',
    testflight: 'https://testflight.apple.com/join/gMNlVK4J',
    browserExtension: {
      chrome: 'https://chrome.google.com/webstore/detail/bitkeep-crypto-nft-wallet/jiidiaalihmmhddjgbnbgdfflelocpak',
      // firefox: '',
      // opera: '',
      edge: 'https://chrome.google.com/webstore/detail/bitkeep-crypto-nft-wallet/jiidiaalihmmhddjgbnbgdfflelocpak',
    },
  }
}



/**
* @description get academy 
* @return void
*/
export const getAcademy = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  const formatLang = lang.substr(0, 2);
  return `https://web3.bitget.com/${formatLang}/academy`;
}

/**
* @description get Blog 
* @return void
*/
export const getBlog = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  const formatLang = lang.substr(0, 2);
  return `https://web3.bitget.com/${formatLang}/blog`;
}

/**
  * @description Wallet Info
  * @return void
*/

export const WalletInfo = {
  id: "bitgetWallet",
  version: version,
  name: 'Bitget Wallet',
  homepage: 'https://web3.bitget.com',
  shortName: "Bitget Wallet",
  description:
    "Your Web3 Crypto Wallet for effortless trading. One of the top non-custodial multi-chain crypto wallet, integrates Wallet, Swap, NFT Market, DApp and Discover in a single location, allowing users to enter the era of Web 3.0 with ease.",
  tags: ["wallet", "Defi", "NFT", "digital_id", "infrastructure"],
  downloadLinks: getDownloadLinks(),
  socialMedia: {
    website: getDownloadLinks().website,
    github: "https://github.com/bitkeepwallet",
    twitter: "https://twitter.com/BitgetWallet",
    twitters: {
      EN: "https://twitter.com/BitgetWallet",
      CN: 'https://twitter.com/BitgetWalletCN',
      Daily: "https://twitter.com/BGWalletDaily"
    },
    medium: "https://bitgetwalletblog.medium.com/",
    discord: "https://discord.gg/qjH6YGDYgh",
    telegram: "https://t.me/Bitget_Wallet",
    telegrams: {
      EN: "https://t.me/Bitget_Wallet",
      CN: 'https://t.me/Bitget_Wallet_CN'
    },
    facebook: "https://www.facebook.com/BitgetWallet",
    instagram: "https://instagram.com/bitgetwallet_global",
    youTube: "https://www.youtube.com/@BitgetWallet",
    linkedIn: "https://www.linkedin.com/company/bitgetwallet/"
  },
  logolist: {
    base64: walletIconBase64,
    png: {
      28: logoPNG28,
      256: logoPNG256
    },
    svg: {
      28: logoSVG28,
      256: logoSVG256
    }
  },
  academy: getAcademy(),
  blog: getBlog(),
  deepLinks: {
    scheme: 'bitkeep://',
    universallink: 'https://bkapp.vip'
  },
};


/**
  * @description SDK Info
  * @return void
*/

export const SDKInfo = {
  version: version,
  name: '@bitget-wallet/web3-sdk',
  language: ['en', 'zh-CN', 'zh-TW', 'tr', 'vi', 'id'],
  description: ''
};


/**
  * @description get sdk version
  * @return void
*/
export const getSDKVersion = () => {
  return version
}

/**
* @description get sdk description
* @return void
*/
export const getSDKDescription = () => {
  return SDKInfo.description || "";
}

/**
  * @description get language
  * @return void
*/

const getLang = (language: string) => {
  return SDKInfo.language.includes(language) ? language : 'en'
}



export const getDeepLinks = () => {
  return WalletInfo.deepLinks
}

/**
* @description get download
* @return void
*/
export const getDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).website
}

/**
* @description get Android Download
* @return void
*/
export const getAndroidDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).android
}


/**
* @description get Ios Download 
* @return void
*/
export const getIosDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).ios
}


/**
* @description get Google Play Download
* @return void
*/
export const getGooglePlayDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).googlePlay
}


/**
* @description get Chrome download
* @return void
*/
export const getChromeDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).chrome
}


/**
* @description get browser Extension
* @return void
*/
export const getAppStoreDownload = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).appleStore
}


export const getBrowserExtension = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).browserExtension
}

/**
* @description get Chrome extension
* @return void
*/
export const getChromeExtension = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).browserExtension.chrome
}

/**
* @description get Edge extension
* @return void
*/
export const getEdgeExtension = (language?: string) => {
  const lang = language ? getLang(language) : 'en'
  return getDownloadLinks(lang).browserExtension.edge
}

/**
* @description get is install bitget
* @return void
*/
export const getIsInstall = () => {
  return (window as any)?.ethereum && (window as any)?.isBitKeep && (window as any)?.bitkeep;
}




