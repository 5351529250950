 
// 删除无用方法

// export function isBitKeep(): boolean {
//   return /BitKeep/i.test(window?.navigator?.userAgent);
// }

export function isMobile(): boolean {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    window?.navigator?.userAgent
  );
}

/**
  * @description is App
  * @return void
*/
export function isApp(): boolean {
  return /BitKeep/i.test(window?.navigator?.userAgent);
}

/**
  * @description is PC
  * @return void
*/
export function isPC(): boolean {
  return !isApp() && !isMobile();
}

/**
  * @description is bitget Moblie
  * @return void
*/
export function isBGMoblie(): boolean {
  return isApp() && isMobile();
}



