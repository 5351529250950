import { AbstractAdapter } from '../adapters/AbstractAdapter';
import { getDownload } from '../constant'
import { installWalletMessage } from '../constant/errorCode'

import type { EIP1193Provider } from './eip1193';
import type { TronLinkProvider, TronLink, TronWeb } from './tronLink';
import type { SolanaProvider } from './solana';


import { isBGMoblie, isDocumentComplete } from '../utils';

export {
  EIP1193Provider,
  SolanaProvider,
  TronLinkProvider,
  TronLink,
  TronWeb,
};

export enum SupportProviderFlag {
  'EVM' = 'eip1193',
  'TRON' = 'tronLink',
  'SOLANA' = 'solana',
}

export const InitProviderEventNames = {
  [SupportProviderFlag.EVM]: 'ethereum#initialized',
  [SupportProviderFlag.TRON]: 'tronLink#initialized',
  [SupportProviderFlag.SOLANA]: '',
};
export interface BitKeepGlobal {
  ethereum: EIP1193Provider;
  tronLink: TronLinkProvider['tronLink'];
  tronWeb: TronLinkProvider['tronWeb'];
  solana: SolanaProvider;
}

export type WaleltProvider<U extends keyof BitKeepGlobal, T> =
  | BitKeepGlobal[U]
  | TronLinkProvider
  | T;

export const getWalletProvierInitEventsName = (
  flag: SupportProviderFlag
): string | undefined => {
  const eventName = InitProviderEventNames[flag];
  return eventName;
};

// const bitkeep: BitKeepGlobal = {
//   ethereum: window.bitkeep.ethereum ,
//   tronLink: window.bitkeep.tronLink,
//   tronWeb: window.bitkeep.tronWeb,
//   solana: window.bitkeep.solana,
// };

/**
 * @description
 * @param flag
 * @returns
 */
export const getWaleltProvider = <T>(
  flag: SupportProviderFlag
): T | WaleltProvider<keyof BitKeepGlobal, T> | null => {
  let provider = null;
  switch (flag) {
    case SupportProviderFlag.EVM:
      provider = window?.bitkeep?.ethereum;
      break;
    case SupportProviderFlag.TRON:
      provider = window?.bitkeep
        ? {
          tronLink: window?.bitkeep.tronLink,
          tronWeb: window?.bitkeep.tronWeb
        }
        : null;
      break;
    case SupportProviderFlag.SOLANA:
      provider = window?.bitkeep?.solana;
      break;
    default:
      break;
  }
  if (!provider) {
    console.warn("The current " + flag + " wallet is not detected!!")
  }
  return provider;
};

export const findFlagAdapter = (
  flag: SupportProviderFlag,
  adapters: AbstractAdapter[]
) => {
  const adapter = adapters.find((adapter) => adapter.providerFlag == flag);
  if (!adapter) throw 'no wallet flag detected!!';
  return adapter;
};


export const currentProvider = () => {
  const downloadURL = getDownload();
  try {
    // check if the provider is  window.bitkeep
    if ((window as any)?.bitkeep) {
      return (window as any)?.bitkeep?.ethereum
    } else {
      // Bitget Wallet Chrome Extension is not installed
      // send user to install page
      window.open(downloadURL, '_blank')
      throw new Error(installWalletMessage);
    }
  } catch (err) {
    console.error(err)
    window.open(downloadURL, '_blank')
    throw new Error(installWalletMessage);
  }
};


type HandleDetectOptions = {
  type: string;
  eventName: string | undefined;
};

/**
 * @param options - Options.
 * @param options.timeout - Milliseconds to wait for '*#initialized' to
 * be dispatched. Default: 1000
 * @returns A Promise that resolves with the Provider if it is detected within
 * given timeout, otherwise null.
 */
function detectBitkeepProvider<T>({
  timeout = 1000,
  flag = SupportProviderFlag.EVM,
} = {}): Promise<WaleltProvider<keyof BitKeepGlobal, T> | null> {
  _validateInputs();

  let isBKMoblie: boolean = isBGMoblie();

  let handled = false;
  let eventName = getWalletProvierInitEventsName(flag);

  return new Promise(async (resolve) => {
    if (window.bitkeep) {
      handleDetect({ type: 'init', eventName });
    } else {
      await isDocumentComplete();

      if (window.bitkeep) {
        handleDetect({ type: 'load', eventName });
      }

      if (eventName && isBKMoblie) {
        window.addEventListener(
          eventName,
          handleDetect.bind(null, { type: 'event', eventName }),
          { once: true }
        );
      }

      setTimeout(() => {
        handleDetect({ type: 'timeout', eventName });
      }, timeout);
    }

    function handleDetect(params: HandleDetectOptions) {
      console.log(params)
      if (handled) {
        return;
      }
      eventName &&
        isBKMoblie &&
        window.removeEventListener(eventName, handleDetect);
      handled = true;
      const { bitkeep } = window;

      if (bitkeep) {
        const provider = flag ? getWaleltProvider<T>(flag) : bitkeep;
        resolve(provider);
      } else {
        resolve(null);
      }
    }
  });

  function _validateInputs() {
    if (typeof timeout !== 'number') {
      throw new Error(
        `@@bitget-wallet/web3-sdk/detect-provider: Expected option 'timeout' to be a number.`
      );
    }
  }
}

export { detectBitkeepProvider };
