
export * from "../provider"
export * from "./userAgent"

/**
  * @description is Document Complete
  * @return void
*/
export const isDocumentComplete = function(): Promise<boolean>{
    let isComplete = false
    return new Promise(resolve=>{
      if (document.readyState === "complete") {
            if(isComplete) return 
             isComplete = true
             resolve(true)
      } else {
        window.onload = function() {
           if(isComplete) return 
           isComplete = true
           resolve(true)
        }
      }
    })
}

