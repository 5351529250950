/**
 * @description Install wallet
 * @param 
 * @returns
 */
export const installWalletMessage = `Please Install <a href="https://web3.bitget.com/wallet-download">Bitget Wallet</a> to use this wallet`

// errorCode 封装一个对象，适配多个规范
// 确认bk钱包code，
// 确认多个规范的code ,根据规范抛出不同的code，和公用code

/**
 * @description Error Code
 * @param 
 * @returns
 */
interface Props {
    code: number
    standard: string,
    message: string
}
export const errorCode: Props[] = [
    {
        code: 4004,
        standard: '',
        message: installWalletMessage,
    },
    {
        code: 4001,
        standard: 'EIP-1193',
        message: 'User rejected the request.',
    },
    {
        code: 4100,
        standard: 'EIP-1193',
        message: 'The requested account and/or method has not been authorized by the user.',
    },
    {
        code: 4200,
        standard: 'EIP-1193',
        message: 'The requested method is not supported by this Ethereum provider.',
    },
    {
        code: 4900,
        standard: 'EIP-1193',
        message: 'The provider is disconnected from all chains.',
    },
    {
        code: 4901,
        standard: 'EIP-1193',
        message: 'The provider is disconnected from the specified chain.',
    },
    {
        code: -32700,
        standard: 'JSON RPC 2.0',
        message: 'Invalid JSON was received by the server. An error occurred on the server while parsing the JSON text.',
    },
    {
        code: -32600,
        standard: 'JSON RPC 2.0',
        message: 'The JSON sent is not a valid Request object.',
    },
    {
        code: -32602,
        standard: 'JSON RPC 2.0',
        message: 'Invalid method parameter(s).',
    },
    {
        code: -32603,
        standard: 'JSON RPC 2.0',
        message: 'Internal JSON-RPC error.',
    },
    {
        code: -32000,
        standard: 'EIP-1474',
        message: 'Invalid input.',
    },
    {
        code: -32001,
        standard: 'EIP-1474',
        message: 'Resource not found.',
    },
    {
        code: -32002,
        standard: 'EIP-1474',
        message: 'Resource unavailable.',
    },
    {
        code: -32003,
        standard: 'EIP-1474',
        message: 'Transaction rejected.',
    },
    {
        code: -32004,
        standard: 'EIP-1474',
        message: 'Method not supported.',
    },
    {
        code: -32005,
        standard: 'EIP-1474',
        message: 'Request limit exceeded.',
    },
]


/**
 * @description Error Code Message
 * @param 
 * @returns
 */

export const errorCodeMessage = (code: string | Number) => {
    try {
        const codeItem = errorCode.filter(item => item.code == code)
        return codeItem[0]?.message
    } catch (err) {
        return installWalletMessage
    }
};

/**
 * @description ErrorCode object
 * @param 
 * @returns
 */
export const errorCodeobject = (code: string | Number) => {
    try {
        const codeItem = errorCode.filter(item => item.code == code)
        return codeItem[0]
    } catch (err) {
        const codeItem = errorCode.filter(item => item.code == 4004)
        return codeItem[0]
    }
};
